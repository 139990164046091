import React from "react";
import "../../App.css";

function SpeakersPage() {
  const speakers = [
    {
      name: "Sónia Ferreira",
      description: "Farmacêutica e Diretora Técnica da Guerbet em Portugal",
      image: "images/Sonia_Ferreira.jpg",
      summary:
        " Natural de Lisboa, é licenciada em Ciências Farmacêuticas pela FFUL desde 1998, trabalhando na indústria farmacêutica há mais de 20 anos. Pós-graduada em Farmacoepidemiologia (INFARMED/Faculdade de Medicina de Lisboa) e em Marketing One-to-One e Comunicação Interativa (IADE). Desde 2004 que exerce as funções de diretora técnica, diretora de qualidade e de assuntos regulamentares, responsável de fármacovigilancia e de dispositivos médicos da Guerbet em Portugal, empresa farmacêutica dedicada à área da Imagiologia. Especialista em Assuntos Regulamentares pela OF, é vice-presidente da APREFAR desde 2014.",
    },
    {
      name: "Ana Baldaia",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Ana_Baldaia.jpg",
      summary:
        "Ana Cristina Baldaia, licenciada em Imagem Médica e Radioterapia pela Escola Superior de Tecnologia da Saúde de Coimbra-IPC. Técnica de Radiologia desde 2018. Desde 2020 no Centro Hospitalar e Universitário de Coimbra, CHUC, E.P.E. Pós-graduação em Ressonância Magnética e Tomografia Computorizada na Escola Superior de Saúde do Vale do Ave. Com início em 2022, frequenta o Mestrado em Imagem Medica e Radioterapia na Escola Superior de Tecnologia da Saúde de Coimbra-IPC.",
    },
    /*      {
      name: "Elza Santo",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "",
      summary: "",
    }, */
    {
      name: "Patricia Oliveira",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Patricia_Oliveira.jpg",
      summary:
        "Licenciada em Imagem Médica e Radioterapia pela Escola Superior de Tecnologia da Saúde de Coimbra desde 2018, e Mestre em Tecnologias da Imagem Médica pela Universidade de Aveiro desde 2021. Atualmente exerce funções como Técnica Superior de Radiologia no Serviço de Imagem Médica–SIM no Centro Hospitalar e Universitário de Coimbra- CHUC,E.P.E.",
    },
    {
      name: "Teresa Neves",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Teresa_Neves.jpg",
      summary:
        "Teresa Sofia Lopes Dias das Neves, licenciada em Radiologia pela Escola Superior de Tecnologia da Saúde de Coimbra-IPC. Mestre em Radiologia- Especialização Osteoarticular, na Escola Superior de Tecnologia da Saúde de Coimbra-IPC. Técnica de Radiologia desde 2003, pertence aos quadros do SIM-CHUC, E.P.E. desde 2004. Monitora de estágios na licenciatura de Radiologia e Imagem Médica e Radioterapia na EstesC-IPC, desde 2005",
    },
    {
      name: "Vitor Silva",
      description:
        "Técnico Superior de Diagnóstico e Terapêutica Radiologia Especialista",
      image: "images/Vitor_Silva.jpg",
      summary:
        "Licenciado em Radiologia pela Escola Superior de Tecnologia de Saúde do Porto, pós-graduado em Gestão e Direção de Serviços de Saúde, pela Porto Business School, Universidade do Porto – distinguido como melhor aluno da sua edição; pós-graduado em Proteção e Segurança contra Radiações Ionizantes pela Escola Superior de Saúde do Porto, em parceria com a UCD Dublin. Mestre em Informática Médica pela Faculdade de Medicina da Universidade do Porto e Doutorado em Segurança e Saúde Ocupacionais na Faculdade de Engenharia da Universidade do Porto. Exerce funções como Técnico Superior de Diagnóstico e Terapêutica Radiologia Especialista no Departamento de Ressonância Magnética, do Serviço de Radiologia do Centro Hospitalar e Universitário de São João, EPE – Técnico Subcoordenador do Serviço de Radiologia CHUSJ - área da RM. É ainda, Professor adjunto convidado no Departamento de Imagem Médica e Radioterapia da Escola Superior de Tecnologia da Saúde de Coimbra, lecionando na Pós Graduação em Ressonância Magnética e em mestrados da ESTESC. Expert na área de Ressonância Magnética pela EFRS (European Federation of Radiographer Society). Inserido no grupo de trabalho “Magnetic Resonance Safety Officer”. Participou várias vezes como orador convidado sobre a temática “MRI Safety”, entre outros temas relacionados com RM, em palestras, cursos, workshops e congressos nacionais e internacionais, dos quais se destacam: Congresso Nacional de Radiologia, Congresso Nacional de Cardiologia, European Congress of Radiology, Congresso Europeu da ESMRMB e Projeto Europeu P3-Stroke. Colabora em alguns projetos nacionais e internacionais na área da Ressonância Magnética, a salientar o Working Group ECSO/MRI (European Curriculum for Safety Officers in Magnetic Resonance Imaging), como representante clínico da EFRS (European Federation of Radiographers Societies) e o WG EFRS-ESMRMB Radiographers WG.",
    },
    {
      name: "Ana Carolina Pimenta",
      description: "Médica Nefrologista CHUC, E.P.E.",
      image: "images/Ana_Pimenta.jpg",
      summary:
        "Ana Carolina Cordeiro Pimenta, Mestre em Medicina pela Faculdade de Medicina da Universidade de Coimbra em 2017. Interna de Formação Específica em Nefrologia no Centro Hospitalar e Universitário de Coimbra desde 2019. Assistente Convidada da Faculdade de Medicina da Universidade de Coimbra para a Unidade Curricular Integrada de Patologia e Terapêutica Médica desde 2021",
    },
    {
      name: "Fátima Duarte",
      description: "Médica Imunoalergologista Hospital de Santa Maria",
      image: "images/Fatima_Duarte.jpg",
      summary:
        "Licenciou-se em Medicina em 2002 na Faculdade de Medicina da Universidade de Lisboa. Terminou a especialidade de Imunoalergologia em 2010 no Serviço de Imunoalergologia do Hospital de Santa Maria, Centro Hospitalar Universitário Lisboa Norte. Desempenha atividade profissional como assistente hospitalar graduada de Imunoalergologia no Hospital de Santa Maria, onde é coordenadora da consulta de Alergia a Meios de Contraste, faz parte da Unidade de Alergia Alimentar, fazendo consultas específicas nestas áreas, é uma das responsáveis do serviço pelo estágio de Imunoalergologia dos grupos etários pediátricos, é orientadora de formação de internos do internato de formação específica em Imunoalergologia, bem como de estágios de Imunoalergologia de outros internos. Membro da Sociedade Portuguesa de Alergologia e Imunologia Clínica (SPAIC) e da European Academy of Allergy and Clinical Immunology (EAACI). Tem sido palestrante em congressos, formadora de cursos e autora de trabalhos na área de Imunoalergologia, nomeadamente de temas relacionados com alergia a meios de contraste, entre outros.",
    },
    {
      name: "Janire Casas Martínez",
      description: "Especialista em Aplicação Clínica, GE Healthcare Iberia",
      image: "images/Janine.jpg",
      summary:
        "Vasta experiência como TSID, tendo-se especializado em várias modalidades da imagiologia, incluindo radiologia convencional, mamografia, densitometria óssea, procedimentos em bloco operatório, radiologia de intervenção, com foco particular em RM e TC. A sua sólida experiência na formação de inúmeros profissionais é bastante reconhecida. Na qualidade de Especialista em Aplicação Clínica, demonstra proficiência em radiologia convencional, imagiologia no leito, procedimentos em bloco operatório e mamografia. Suas habilitações incluem configuração de processamento de imagens e estabelecimento de conexões remotas. Atualmente, ocupa o cargo de Especialista em Aplicação Clínica para meios de contraste na GE Healthcare Iberia. Nesta função, conduz sessões de formação e assiste os profissionais de saúde na utilização eficaz dos meios de contraste.",
    },
    {
      name: "Daniela Jardim Pereira",
      description: "Médica Unidade Funcional de Neuroradiologia CHUC, E.P.E.",
      image: "/images/Daniela_Jardim.jpg",
      summary:
        "Daniela Jardim Pereira obteve a especialidade de Neurorradiologia em 2017 e o doutoramento pela Faculdade Medicina da Universidade de Coimbra em 2023. É médica na Unidade Funcional de Neurorradiologia (Serviço de Imagem Médica) do Centro Hospitalar e Universitário de Coimbra desde 2012, onde integra também as equipas multidisciplinares de epilepsia refractária, neuro-oncologia e doenças desmielinizantes. Exerce ainda atividade clínica no Centro Hospitalar de Leiria e Centro Hospitalar do Médio Tejo. Tem como área de diferenciação as técnicas avançadas de ressonância magnética, recebendo internos da especialidade de vários hospitais do país para realização de estágios específicos neste campo. Tem também vasta experiência em neuroimagem numa perspectiva translacional, integrando a sua atividade clínica com a investigação no CIBIT/ICNAS (Coimbra Institute for Biomedical Imaging and Translational Research), nomeadamente no desenvolvimento e aplicação de novas técnicas de aquisição e processamento. Atualmente, tem vindo a desenvolver particular interesse pelos modelos de inteligência artificial quer para aplicação diagnóstica na neurorradiologia, quer numa perspectiva predictiva de auxílio à decisão terapêutica.",
    },
    {
      name: "Henrique Donato",
      description: "Médico Radiologista CHUC, E.P.E.",
      image: "images/Henrique_Donato.jpg",
      summary:
        "Assistente de Radiologia no Serviço de Imagem Médica do Centro Hospitalar e Universitário de Coimbra desde 2018, dedicado às áreas de Radiologia Geral e Radiologia Pediátrica. Primeiro autor de mais de 20 comunicações e publicações científicas. Co-autor de várias outras comunicações e publicações científicas. Membro da Sociedade Portuguesa de Radiologia e Medicina Nuclear, da European Society of Radiology e da European Society of Musculoskeletal Radiology.",
    },
    {
      name: "Sandra Assunção",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Sandra_Assuncao.jpg",
      summary:
        "Licenciada em Imagem Médica e Radioterapia pela Escola Superior de Tecnologia e Saúde de Coimbra-IPC. Prémio de melhor comunicação em congresso da ATARP, para trabalho de final de licenciatura. A exercer funções como técnica de radiologia desde o ano da formatura, no sector privado e público. Atualmente faz parte do quadro do Serviço de Imagem Médica-SIM do CHUC, E.P.E., com contrato CIT. Tutora da licenciatura de IMR da ESSUA desde 2022. Durante o ano de 2023, colaborou no secretariado do CIRC. Convidada pela comissão científica do CIRC para moderar a conferência internacional “Saber identificar os catéteres e tubos num raio-x do TÓRAX”, por Amandar Er. Técnica de Radiologia e formadora clínica em Singapura. Colaboradora da Universidade de Monash em Melbourne, Austrália.",
    },
    {
      name: "Teresa Pedro",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Teresa_Pedro.jpg",
      summary:
        "Licenciada em Radiologia pela Escola Superior de Tecnologia de Saúde de Coimbra-IPC; Mestre em Radiologia- Especialização Osteoarticular. Pós-graduação em Ressonância Magnética pela Escola Superior de Tecnologia de Saúde de Coimbra-IPC. Exerceu a sua atividade no sector privado e atualmente pertence ao quadro do serviço de imagem médica- SIM do CHUC, E.P.E.. Pertenceu à comissão organizadora de diferentes eventos da área da radiologia enquanto estudante e posterirormente. Gosta de desafios, e este ano, em colaboração com outras colegas apresentou um projeto sobre o circuito do doente no SIM à INOVAR+(plataforma interna do CHUC, E.P.E.), e foi um dos 10 selecionados. Artigos em revista generalistas e científicas. Comunicações e poster nacionais e internacionais.",
    },
    {
      name: "Rogério Nuno Costa",
      description: "Técnico de Radiologia Hospital das Forças Armadas",
      image: "images/Rogerio.jpg",
      summary:
        "Licenciatura em Radiologia e mestre em Técnicas Avançadas de Imagem em Radiologia. Suporte avançado de vida. Medical care under fire TCCC Consultor da Advanced Medical Systems- Portugal Técnico Especialista de Dispositivos Médicos https://www.ams.pt/cirurgia. Desde 2020 à atualidade Professor Convidado da Escola Superior de Saúde da Cruz Vermelha Portuguesa.",
    },
    {
      name: "Joana Sousa",
      description: "Especialista em aplicações da QAELUM-BÉLGICA",
      image: "images/Joana_Sousa.jpg",
      summary:
        "Joana Sousa, Licenciada em Radioterapia pela Escola Superior de Tecnologias da Saúde de Lisboa em 2011. Exerceu funções como técnica de Radioterapia em Portugal e Bélgica até 2022. Atualmente, é Especialista de Aplicações na empresa QAELUM - Bélgica.",
    },
    {
      name: "Victoria Leiro",
      description:
        "Investigadora INEB - Instituto de Engenharia Biomédica e i3S - Instituto de Investigação e Inovação em Saúde",
      image: "/images/Vitoria_Leiro.jpg",
      summary:
        "Victoria Leiro é doutorada em Química Orgânica pela Universidade de Santiago de Compostela (Espanha). Desde 2011, Dr. Leiro é investigadora no grupo nBTT-nanoBiomaterials for Targeted Therapies do INEB - Instituto de Engenharia Biomédica e do i3S - Instituto de Investigação e Inovação em Saúde (Porto, Portugal). Neste grupo, a sua sólida formação em química orgânica e de polímeros, permitiram-lhe estabelecer uma nova linha de investigação focada no desenvolvimento de nanobiomateriais dendríticos “inteligentes”, versáteis e biodegradáveis para aplicação na área da nanomedicina. Dr. Leiro tem explorado estes dendrímeros biodegradáveis como vetores de distintos ácidos nucleicos terapêuticos direcionados a vários tecidos como o sistema nervoso periférico e central, e tecido cartilaginoso da articulação e do disco intervertebral, visando promover regeneração e proteção destes tecidos. Atualmente, Dr. Leiro está a alargar o foco da sua pesquisa para a área da teranóstica, explorando estes nanosistemas dendríticos como agentes de contraste de imagem para tomografia computadorizada.",
    },
    {
      name: "César Nunes",
      description: "Médico Neurorradiologia CHUC, E.P.E.",
      image: "images/Cesar_Nunes.jpg",
      summary:"César Nunes, médico Neurorradiologista dedicado fundamentalmente à intervenção endovascular e com especial interesse no AVC isquémico. Forma parte do grupo de intervenção da Neurorradiologia dos Hospitais da Universidade de Coimbra, grupo que com três elementos conseguiu realizar, só no ano passado, mais de 620 trombectomias, tornando-se líderes em número de trombectomias na península Ibérica e em Europa.",
    },
      {
      name: "Carla Barbosa",
      description: "Investigadora associada Universidade de Coimbra",
      image: "/images/Carla_Barbosa.jpg",
      summary:
        'Investigadora associada do Centro de Direito Biomédico e do Instituto Jurídico da Faculdade de Direito da Universidade de Coimbra; Docente e Investigadora do Jean Monnet Center of Excellence "Building the Age of a Lawful and sustainable Data-Use" (BALDUS) – Universidade de Perugia; Especialista em Privacidade e Dados Pessoais pelo Conselho da Europa',
    },
    {
      name: "Joana Madureira",
      description: "Técnica de Radiologia SUB de Arouca - ARS Norte",
      image: "images/Joana_Madureira.jpg",
      summary:
        "Licenciada em Radiologia pela Escola Superior de Tecnologia da Saúde de Coimbra desde 2001. Iniciou funções como Técnica de Radiologia no CHUC, desenvolvendo maioritariamente a sua atividade profissional na área da radiologia pediátrica. Atualmente, exerce a sua atividade profissional na SUB de Arouca - ARS Norte. Vogal da Direção Nacional da ATARP desde novembro de 2021, colaborando no Grupo de Trabalho da Radiologia Pediátrica.",
    },
       {
      name: "Carla Solano",
      description: "Técnica de Radiologia CHUC, E.P.E.",
      image: "images/Carla_Solano.jpg",
      summary:
        "Nasceu em Moçambique, 1961, vive em Coimbra. Profissional na área da imagem médica, licenciada em Radiologia pela Escola Superior de Tecnologias de Saúde de Coimbra (ESTESC-IPC). Mestre em  Antropologia Médica pela Universidade de Coimbra (U.C.), desenvolvendo estudos na área dos Estudos Sociais de Ciência e Técnica (SST) e em Ressonância Magnética Cerebral funcional (fMRI). MBA em Gestão Hospitalar. Estudos graduados sobre interdisciplinaridade, no programa doutoral “Estudos Contemporâneos” da UC.  Investigadora no Centro em Rede de Investigação em Antropologia (CRIA), entre 2011 e 2015.  Investigadora convidada do grupo Ceis 20,  Instituto de Investigação Interdisciplinar da U.C., desde 2015. Desenvolve estudos sobre a história das práticas e saberes da ciência radiológica. As áreas de interesse de pesquisa são:  corpo, imagens, ciência.",
    },
  ];

  return (
    <div className="speakers-container">
      <h1>Palestrantes</h1>
      {speakers.map((speaker, index) => (
        <div key={index} className="speaker-card">
          <img
            src={speaker.image}
            alt={speaker.name}
            className="speaker-image"
          />
          <h3 className="speaker-name">{speaker.name}</h3>
          <p className="speaker-summary">{speaker.summary}</p>
        </div>
      ))}
    </div>
  );
}

export default SpeakersPage;
