import React from "react";
import "../../App.css";

function ExhibitionPage() {
  return (
    <div className="ExhibitionPage-container">
      <h1>Exposição</h1>
      <h2>Os Meios de Contraste em Radiologia e Outros Territórios</h2>
      <p>
        Um dos princípios dos nossos eventos é que tragam disrupções na forma
        como os trabalhamos e construímos.
      </p>
      <p>
        Outro princípio é cruzá-los com a cidade e que extravasem o programa do
        próprio evento. Devem também ter interesse para a cidade, para o turismo
        local e que contribuam, profissionalmente, para nos colocar num patamar
        diferenciado.
      </p>
      <p>
        É neste espírito que propomos uma exposição “de arte” intitulada “Os
        Meios de Contraste em Radiologia e Outros Territórios”.
      </p>
      <p>
        “Os Meios de Contraste em Radiologia e Outros Territórios” constitui-se
        como uma exposição “de arte” através de conteúdos fornecidos pelos
        nossos parceiros, players da área de Radiologia.
      </p>
      <p>
        Utilizada a curadoria da comissão organizadora, uma exposição de arte
        designa tradicionalmente o espaço e tempo onde objetos de arte vão ao
        encontro de um público.
      </p>
      <p>
        Os dois eventos, Radiologia ao Centro-<i>knowledge transforms lives</i>{" "}
        e “Os Meios de Contraste em Radiologia e Outros Territórios” são
        indissociáveis, e trazem novidades tanto pelo financiamento como no
        acesso.
      </p>
      <p>O formato tem a seguinte forma:</p>
      <ol>
        <li>
          Fornecimento de imagens e texto sobre o que o parceiro quiser,
          trabalhadas posteriormente em painéis
        </li>
        <li>
          Liberdade para os parceiros convidarem os seus clientes a visitarem a
          exposição
        </li>
        <li>
          A Exposição fica situada entre o auditório e o refeitório, e prevê-se
          a passagem de entre 130/160, só de inscritos, oradores e convidados{" "}
        </li>
        <li>Todos os objetos fornecem um QRCODE direcionado para o parceiro</li>
        <li>
          Mais uma vez tentamos reduzir custos aos parceiros e rentabilizar os
          gastos
        </li>
      </ol>
    </div>
  );
}

export default ExhibitionPage;
