import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Programa from "./components/pages/Programa";
import CommissionsPage from "./components/pages/CommissionsPage";
import Footer from "./components/Footer";
import SpeakersPage from "./components/pages/Speakers";
import ExhibitionPage from "./components/pages/ExhibitionPage";
import CookieConsent from "./components/CookieConsent";
import Sponsors from "./components/Sponsors";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import CookiePolicy from "./components/pages/CookiePolicy";
import Presentation from "./components/pages/Presentation";

function App() {
  return (
    <>
      <Router>
        <CookieConsent />
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/programa" element={<Programa />} />
          <Route path="/commissions" element={<CommissionsPage />} />
          <Route path="/speakers" element={<SpeakersPage />} />
          <Route path="/exhibition" element={<ExhibitionPage />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/Presentation" element={<Presentation />} />
        </Routes>
        <Sponsors />
        <Footer />
      </Router>
    </>
  );
}

export default App;
