import React from "react";
import Sponsor from "./Sponsor";
import "./Sponsors.css";

function Sponsors() {
  const sponsors = [
    { image: "images/ULS_CBR.png", group: "Coorganização" },
    { image: "images/seminario_logo.png", group: "Coorganização" },
    { image: "images/hemisferio_logo.png", group: "Coorganização" },
    { image: "images/turismo_logo.png", group: "Parceiros Institucionais" },
    { image: "images/cmcoimbra_logo.png", group: "Parceiros Institucionais" },
    { image: "images/republica_logo.png", group: "Parceiros Institucionais" },
    { image: "images/sms_logo.png", group: "Parceiros Institucionais" },
    { image: "images/presidente_logo.png", group: "Parceiros Institucionais" },
    { image: "images/ge_logo.png", group: "Parceiros Comerciais" },
    {image: "images/bayer_logo.png", group: "Parceiros Comerciais" },
    {image: "images/fujifilm_logo.png", group: "Parceiros Comerciais" },
    {image: "images/qaelum_logo.png", group: "Parceiros Comerciais" },
    {image: "images/guebert.png", group: "Parceiros Comerciais" },
    {image:"images/rovi_logo.png", group: "Parceiros Comerciais" },
    {image:"images/hologic.png", group: "Parceiros Comerciais" },
    {image: "images/aprad_logo.png", group: "Parceiros Comerciais" },
    {text: "Duas Faces", group: "Outros Parceiros" },
    {text: "Contrarelógio", group: "Outros Parceiros" },
    {text: "Delta Cafés", group: "Outros Parceiros" },
    {text: "Água de LUSO", group: "Outros Parceiros" },
    {text: "Conceito Contínuo", group: "Outros Parceiros" },
    {text: "EVENTQUALIA", group: "Outros Parceiros" },
    {text: "Rebelo, AG", group: "Outros Parceiros" },
    {text: "Publiseg", group: "Outros Parceiros" },
    {text: "Paulo Nuno Fotografia", group: "Outros Parceiros" },
    {text: "Chocolataria EQUADOR", group: "Outros Parceiros" }, 
    ];

    const groupedSponsors = sponsors.reduce((acc, sponsor) => {
      acc[sponsor.group] = acc[sponsor.group] || [];
      acc[sponsor.group].push(sponsor);
      return acc;
    }, {});
  
    return (
      <div className="sponsors-container">
        {Object.entries(groupedSponsors).map(([group, sponsorsInGroup]) => (
          <div key={group} className="sponsor-group">
            <h2>{group}</h2>
            <div className="sponsors-list">
              {sponsorsInGroup.map((sponsor, index) => (
                sponsor.image ? (
                  <Sponsor key={index} image={sponsor.image} />
                ) : (
                  <div className="sponsors-list-name">
                    <Sponsor key={index} text={sponsor.text} />
                  </div>
                )
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default Sponsors;



