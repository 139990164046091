import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Card() {
  return (
    <div className="cards">
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/img-1.jpg"
              text="Moldando Futuros."
              label="Apresentação"
              path="/presentation"
            />
            <CardItem
              src="images/img-6.jpg"
              text="Cronograma do Evento."
              label="Programa"
              path="/programa"
            />
            <CardItem
              src="images/img-5.jpg"
              text="Impulsionando Futuros."
              label="Palestrantes"
              path="/speakers"
            />

            <CardItem
              src="images/Seminario2.jpg"
              text="Meios de contraste em Radiologia e Outros Territórios."
              label="Exposição"
              path="/exhibition"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Card;
