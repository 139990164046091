import React from "react";

function CookiePolicy() {
  return (
    <div className="ExhibitionPage-container">
      <h1>Política de Cookies</h1>

      <p>
        Usamos cookies no nosso site para melhorar o desempenho e a sua
        experiência como utilizador. Esta página explica como o fazemos.
      </p>

      <h2>O que são cookies</h2>

      <p>
        Os cookies são pequenos ficheiros de texto que um site, ao ser visitado
        pelo utilizador, coloca no seu computador ou no seu dispositivo móvel
        através do navegador de internet (browser). A colocação de cookies
        ajudará o site a reconhecer o seu dispositivo na próxima vez que o
        utilizador o visitar.
      </p>

      <p>
        Usamos o termo cookies nesta política para referir todos os ficheiros
        que recolhem informações desta forma.
      </p>

      <p>
        Os cookies utilizados não recolhem informação que identifica o
        utilizador. Os cookies recolhem informações genéricas, designadamente a
        forma como os utilizadores chegam e utilizam os sites ou a zona do
        país/países através do qual acedem ao site, etc.
      </p>

      <p>
        Os cookies retêm apenas informação relacionada com as suas preferências.
      </p>

      <p>
        A qualquer momento o utilizador pode, através do seu navegador de
        internet (browser) decidir ser notificado sobre a receção de cookies,
        bem como bloquear a respetiva entrada no seu sistema.
      </p>

      <p>
        A recusa de uso de cookies no site, pode resultar na impossibilidade de
        ter acesso a algumas das suas áreas ou de receber informação
        personalizada.
      </p>

      <h2>Para que servem os cookies</h2>

      <p>
        Os cookies são usados para ajudar a determinar a utilidade, interesse e
        o número de utilizações dos sites, permitindo uma navegação mais rápida
        e eficiente e eliminando a necessidade de introduzir repetidamente as
        mesmas informações.
      </p>

      <h2>Que tipo de cookies utilizamos</h2>

      <p>Os nossos cookies têm diferentes funções:</p>

      <ul>
        <li>
          <strong>Cookies essenciais:</strong> Alguns cookies são essenciais
          para aceder a áreas específicas do nosso site.
        </li>
        {/* ... Outros tipos de cookies ... */}
      </ul>

      <p>
        Em resumo, os cookies de funcionalidade guardam as preferências do
        utilizador relativamente à utilização do site, de forma que não seja
        necessário voltar a configurar o site cada vez que o visita.
      </p>

      <p>
        <strong>
          Uso de cookies na abertura das nossas newsletters e emails marketing:
        </strong>
      </p>

      <p>
        As nossas newsletters e emails marketing podem, para fins estatísticos,
        conter um único "pixel" que nos permite saber se são abertas e verificar
        os cliques através de links ou anúncios dentro da newsletter e/ou emails
        marketing.
      </p>

      <p>
        O utilizador tem sempre a possibilidade de desativar o envio da
        newsletter e/ou email marketing.
      </p>

      <h2>Mais informação sobre cookies</h2>

      <p>
        Pode obter mais informações sobre cookies no seguinte link:{" "}
        <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.
      </p>
    </div>
  );
}

export default CookiePolicy;
