import React from "react";
import "./Sponsors.css";

function Sponsor({ image, text}) {
  return (
    <div className="sponsor">
      {image && <img src={image} alt="sponsor" />}
      {text && <p>{text}</p>}
    </div>
  );
}

export default Sponsor;
