import "./Footer.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Modal from "./Modal";

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleLinkClick = (event) => {
    event.preventDefault();
    setShowPopup(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrolltoSponsors = () => {
    window.scrollTo({
      top: 1400,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <div className="input-areas">
          <h2 className="footer-subscription-heading">Localização</h2>
          <h3>Seminário Maior de Coimbra</h3>
          <p>R. Vandelli 2, 3004-547 Coimbra</p>
          {/* Link "Como Chegar" para o Google Maps */}
          <a
            href="https://maps.app.goo.gl/jZpfKo5FTKAPp7to8"
            target="_blank"
            rel="noopener noreferrer"
            className="how-to-get"
          >
            <h3>⟟ Como Chegar</h3>
          </a>
        </div>
      </section>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Evento</h2>
            <Link to="/presentation" onClick={scrollToTop}>
              Apresentação
            </Link>
            <Link
              to="/"
              onClick={() => {
                setIsModalOpen(true);
                scrollToTop();
              }}
            >
              Inscrições
            </Link>
            {isModalOpen && (
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            )}

            <Link to="/" onClick={handleLinkClick}>
              Cartaz
            </Link>
            {showPopup && (
              <div className="popup">
                <div className="popup_inner">
                  <img src="images/cartaz.jpg" alt="Popup" />
                  <button onClick={() => setShowPopup(false)}>Fechar</button>
                </div>
              </div>
            )}
            <Link to="/programa" onClick={scrollToTop}>
              Programa
            </Link>
            <Link to="/" onClick={scrolltoSponsors}>
              Parceiros
            </Link>
            <Link to="/exhibition" onClick={scrollToTop}>
              Exposição
            </Link>
            <Link to="/speakers" onClick={scrollToTop}>
              Palestrantes
            </Link>
            <Link to="/commissions" onClick={scrollToTop}>
              Comissões
            </Link>
          </div>

          <div className="footer-link-items">
            <h2>Sobre</h2>
            <Link to="/presentation" onClick={scrollToTop}>Conceito</Link>
            <Link to="/">Associação</Link>
            <Link to="https://www.circ-cb.com/">CIRC2023</Link>
            <Link to="/privacy-policy" onClick={scrollToTop}>
              Política de Privacidade
            </Link>
            <Link to="/terms-of-use" onClick={scrollToTop}>
              Termos de Utilização
            </Link>
            <Link to="/cookie-policy" onClick={scrollToTop}>
              Política de Cookies
            </Link>
          </div>

          <div className="footer-link-items">
            <h2>Contactos</h2>
            <Link to="/">radiologiaaocentro@gmail.com</Link>
            <Link to="/" className="contact-number">
              <span className="phone-number">☏ 917635120</span>
              <span className="call-info">
                (Chamada para rede móvel nacional)
              </span>
            </Link>
          </div>
        </div>
      </div>

      <section className="social-media">
        <div className="social-media-wrap">
          <small className="website-rights">
            Hemisfério Disciplinado © 2023
          </small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to="https://www.facebook.com/radiologia.ao.centro"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </Link>
            <Link
              className="social-icon-link instagram"
              to="https://www.instagram.com/radiologia.ao.centro/"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </Link>
            <Link
              className="social-icon-link linkedin"
              to="https://www.linkedin.com/company/radiologia-ao-centro/"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
      <section className="footer-signature">
        <p>
          💡Desenvolvido por{" "}
          <Link to="https://www.linkedin.com/in/tiagofaraujo/">
            Tiago Araújo
          </Link>
        </p>
      </section>
    </div>
  );
}

export default Footer;
