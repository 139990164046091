import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./CookieConsent.css";

const CookieConsent = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    Cookies.get("acceptedCookies")
  );

  const acceptCookies = () => {
    Cookies.set("acceptedCookies", "true", { expires: 15 });
    setAcceptedCookies(true);
  };

  const rejectCookies = () => {
    Cookies.remove("acceptedCookies");
    setAcceptedCookies(true);
  };

  if (acceptedCookies) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>
        Este site utiliza cookies para melhorar a sua experiência. Ao continuar
        a navegar, você concorda com o uso de cookies.
      </p>
      <br></br>
      <div>
        <button onClick={acceptCookies}>Aceitar Cookies</button>
        <button onClick={rejectCookies}>Rejeitar</button>
        <Link to="/cookie-policy" className="more-info-link">
          Saber Mais
        </Link>
      </div>
    </div>
  );
};

export default CookieConsent;
