import "../App.css";
import "./HeroSection.css";
import Button from "./Button";
import React from "react";
import Countdown from "./Countdown";

function HeroSection() {
  const targetDate = new Date("2024-02-02T23:59:59");
  return (
    <div className="hero-container">
      <h1>MEIOS DE CONTRASTE</h1>
      <p>knowledge transforms lives</p>
      <div>
        <br></br>
        <Countdown targetDate={targetDate} />
      </div>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          <b>INSCRIÇÕES</b>
        </Button>
      </div>
      <br></br>
      <div className="green-border-group">
        <div className="green-border">
          SCIENTIFIC MEETING
          <div className="subtitle"></div>3 FEVEREIRO 2024
        </div>
        <div className="green-border">
          COIMBRA
          <div className="subtitle">SEMINÁRIO MAIOR</div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
