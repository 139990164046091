import React, { useState, useEffect, useCallback } from "react";
import "./Countdown.css";

const Countdown = ({ targetDate }) => {
  const calculateTimeRemaining = useCallback(() => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(
      (difference % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { weeks, days, hours, minutes, seconds };
  }, [targetDate]);

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeRemaining]);

  if (
    timeRemaining.weeks === 0 &&
    timeRemaining.days === 0 &&
    timeRemaining.hours === 0 &&
    timeRemaining.minutes === 0 &&
    timeRemaining.seconds === 0
  ) {
    return <div>Bem-vindo!</div>;
  }
return (
  <div className="countdown-container">
    <span className="countdown-item">{timeRemaining.weeks} semanas </span>
    <span className="countdown-item">{timeRemaining.days} dias </span>
    <span className="countdown-item">{timeRemaining.hours} horas </span>
    <span className="countdown-item">{timeRemaining.minutes} minutos</span>
    <span className="countdown-item">{timeRemaining.seconds} segundos </span>
  </div>
);
}

export default Countdown;

