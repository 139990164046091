import React from "react";
import "../../App.css";

function Presentation() {
  return (
    <div className="ExhibitionPage-container">
      <h1>Radiologia ao Centro</h1>
      <h2>
        <i>knowledge transforms lives</i>{" "}
      </h2>
      <p>
        Na nossa interpretação, os eventos científicos são atividades que
        procuram reunir experts e interessados nas diversas questões científicas
        de uma determinada temática. Consagram, usualmente, o estado da arte,
        como ponto de partida. Atuam também como plataformas de divulgação dos
        trabalhos dos autores/parceiros promovendo e incentivando campos de
        interesse e foco.
      </p>
      <p>
        A Radiologia ao Centro- 𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀 é uma iniciativa de
        um grupo de licenciados em radiologia para que profissionais da área,
        pesquisadores, docentes e estudantes tenham a possibilidade de ouvir um
        conjunto de oradores escolhidos entre os nossos parceiros e
        coorganizadores, para apresentarem e divulgarem a agenda científica no
        século XXI.
      </p>
      <p>
        A Radiologia ao Centro- 𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀 combina o conceito de
        jornadas com cursos. Um conceito híbrido, que se socorre de sínteses
        diversas, e que pretende proporcionar períodos de aquisição de
        habilidades práticas ao mesmo tempo que se adquirem conhecimentos
        teóricos
      </p>
    </div>
  );
}

export default Presentation;
