import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

const Modal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal_inner">
            <p style={{ marginBottom: "20px", textAlign: "justify" }}>
              <b>Confira o que está incluído na inscrição</b> (preço: 45€
              c/iva):
            </p>
            <ul style={{ listStyleType: "none", paddingInlineStart: "10px" }}>
              <li>-Estacionamento gratuito</li>
              <li>-Coffee-break</li>
              <li>-Almoço</li>
              <li>-Documentação</li>
              <li>-Visita à exposição</li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  backgroundColor: "#808080",
                  color: "#fff",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                Fechar
              </button>
              <a
                href="https://radiologiaaocentro.eventqualia.net/pt/radiologia-ao-centro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  style={{
                    backgroundColor: "#0aac29",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Inscrever-se
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
