import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "./Footer.css";
import Modal from "./Modal";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Navbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [click, setClick] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleLinkClick2 = (event) => {
    event.preventDefault();
    setShowPopup(true);
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            onClick={() => {
              closeMobileMenu();
              scrollToTop();
            }}
          >
            <img src="logo.png" alt="" style={{ width: "200px" }} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToTop();
                }}
              >
                INÍCIO
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={handleLinkClick2}>
                CARTAZ
              </Link>

              {showPopup && (
                <div className="popup">
                  <div className="popup_inner">
                    <img src="images/cartaz.jpg" alt="Popup" />
                    <button onClick={() => setShowPopup(false)}>Fechar</button>
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item">
              <Link
                to="/programa"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToTop();
                }}
              >
                PROGRAMA
              </Link>
            </li>

            <li>
              <Link
                to="/"
                className="nav-links nav-links--special"
                onClick={() => {
                  setIsModalOpen(true);
                  scrollToTop();
                }}
              >
                INSCRIÇÕES
              </Link>
              {isModalOpen && (
                <Modal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
