import React from "react";
import "../../App.css";

export default function Programa() {
  return (
    <div className="programa-container">
      <h2>Programa Oficial </h2>

      <table className="time-schedule-table">
        <thead>
          <tr>
            <th>Hora</th>
            <th>Atividade</th>
            <th>Oradores</th>
            <th>Entidade</th>
          </tr>
        </thead>
        <tbody>
          <tr className="special-row">
            <td>08.00</td>
            <td colSpan="4">Check in</td>
          </tr>
          <tr>
            <td>09.00</td>
            <td colSpan="4">Boas vindas</td>
          </tr>
          <tr>
            <td>09.10</td>
            <td>
              <b>Do passado ao futuro. Os baritados.</b>
            </td>
            <td>Sónia Ferreira</td>
            <td>Diretora Técnica da GUERBET</td>
          </tr>
          <tr>
            <td>09.20</td>
            <td>
              <b>Da prescrição à injeção.</b>
            </td>
            <td>Ana Baldaia; Elza Santo</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>09.30</td>
            <td>
              <b>Injeção; extravasamentos. Atualização baseada em evidência.</b>
            </td>
            <td>Patrícia Oliveira; Teresa Neves</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>09.40</td>
            <td colSpan="4">Questões</td>
          </tr>
          <tr>
          <td>09.55</td>
            <td>
              <b>Os meios de contraste iodados.</b>
            </td>
            <td>Vitor Silva</td>
            <td>CHUSJ/ROVI</td>
          </tr>
          <tr>
            <td>10.05</td>
           
            <td>
              <b>
                Lesão renal aguda pós-contraste e nefropatia induzida por
                contraste em adultos.
              </b>
            </td>
            <td>Carolina Pimenta</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>10.15</td>
            <td colSpan="4">Questões</td>
          </tr>
          <tr>
            <td>10.30</td>
            <td>
              <b>Os meios de contraste à base de gadolínio.</b>
            </td>
            <td>Janire</td>
            <td>GE Healthcare Iberia</td>
          </tr>
          <tr>
            <td>10.40</td>
            <td>
              <b>Fibrose Nefrogénica Sistémica (NSF).</b>
            </td>
            <td>Daniela Jardim Pereira</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>10.50</td>
            <td>
              <b>Um estudo português.</b>
            </td>
            <td>Fátima Duarte</td>
            <td>CHULN, E.P.E.</td>
          </tr>
          <tr>
            <td>10.50</td>
            <td colSpan="4">Questões</td>
          </tr>
          <tr>
            <td>11.20</td>

            <td>
              Abertura oficial do evento com visita à{" "}
              <b> Exposição ”Os Meios de Contraste e Outros Territórios” </b>
            </td>
            <td colSpan="2" >Idealizada e Curadoria da Comissão Organizadora</td>
          </tr>
          <tr className="special-row">
            <td>12.00</td>
            <td colSpan="4">Almoço</td>
          </tr>
          <tr>
            <td>14.00</td>
            <td>
              <b>Tratamento de reações a meios de contraste.</b>
            </td>
            <td>Rogério Costa</td>
            <td>Hospital das Forças Armadas</td>
          </tr>
          <tr>
            <td>14.10</td>
            <td>
              <b>Meios de contrastes em crianças/pediatria.</b>
            </td>
            <td>Henrique Donato</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>14.20</td>
            <td>
              <b>Da gravidez à amamentação. O que dizer?</b>
            </td>
            <td>Sandra Assunção; Teresa Pedro</td>
            <td>CHUC, E.P.E.</td>
          </tr>
                   <tr>
            <td>14.30</td>
            <td colSpan="4">Questões</td>
          </tr>
          <tr>
           <td>14.45</td>
            <td>
              <b>A mama, os meios de contraste e reações adversas.</b>
            </td>
            <td>
              Gina Nogueira Pinto; Maria
              Conceição Nogueira; Maria de Fátima Vieira; Marlene Santos
            </td>
            <td>CHUSA, EPE</td>
          </tr>
          <tr>
            <td>14.55</td>
            <td>
              <b>
                Entre a gestão de contraste e da dose de radiação do paciente. 
              </b>
            </td>
            <td>Joana Sousa</td>
            <td>Especialista em aplicações da QAELUM-BÉLGICA</td>
          </tr>
          <tr>
            <td>15.05</td>
            <td>
              <b>O contraste na era da IA.</b>
            </td>
            <td>(por confirmar)</td>
            <td></td>
          </tr>
          <tr>
            <td>15.15</td>
            <td colSpan="4">Questões</td>
          </tr>
          <tr>
            <td>15.30</td>
            <td> <p>Conferência</p>
              <b>
                A investigação e os agentes de contraste inovadores e
                biodegradáveis para melhorar a precisão no diagnóstico de AVC,
                em TC.
              </b>
            </td>
            <td>Victória Leiro</td>
            <td>
              {" "}
              Instituto de Engenharia Biomédica e do i3S - Instituto de
              Investigação e Inovação em Saúde - Porto
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>MESA REDONDA</b>
            </td>
            <td> César Nunes; Daniela Pereira; Rosa Gaspar</td>
            <td>CHUC, E.P.E.</td>
          </tr>
          <tr>
            <td>16.10</td>
            <td>
            <p>Conferência</p>
              <b>A legislação, a punção venosa e os TSDT.</b>
            </td>
            <td>Carla Barbosa</td>
            <td>Centro de Direito Biomédico e do Instituto Jurídico da FDUC</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>MESA REDONDA</b>
            </td>
            <td>Carla Solano; Joana Madureira; Rogério Costa</td>
            <td></td>
          </tr>
          <tr className="special-row">
            <td>17.00</td>
            <td colSpan="4">Encerramento</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
