import React from "react";
import "../../App.css";

function PrivacyPolicy() {
  return (
    <div className="ExhibitionPage-container">
      <h1>Política de Privacidade</h1>

      <h2>1. Identificação do Responsável pelo Tratamento</h2>
      <p>
        Nome: Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i> Sede: Rua
        João Moreno, Lote 1, Loja B 3030-776, Coimbra.
      </p>
      <p>
        Contacto Encarregado da Proteção de Dados: Carla Maria Solano Máximo da
        Rocha Email: ahd-geral@outlook.com
      </p>

      <h2>2. Informação e Consentimento</h2>
      <p>
        A Lei da Proteção de Dados Pessoais (LPDP) e o Regulamento Geral de
        Proteção de Dados (RGPD) asseguram a proteção das pessoas singulares no
        que diz respeito ao tratamento de dados pessoais e à livre circulação
        desses dados.
      </p>
      <p>
        Nos termos legais são considerados "dados pessoais" qualquer informação,
        de qualquer natureza e independentemente do respetivo suporte, incluindo
        som e imagem, relativa a uma pessoa singular identificada ou
        identificável, pelo que a proteção não abrange os dados de pessoas
        coletivas.{" "}
      </p>
      <p>
        {" "}
        Mediante a aceitação da presente Política de Privacidade o utilizador
        presta o seu consentimento informado, expresso, livre e inequívoco para
        que os dados pessoais fornecidos através do site
        https://www.radiologiaaocentro.com/ sejam incluídos num ficheiro da
        responsabilidade do Radiologia ao Centro -
        <i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, cujo tratamento nos termos da LPDP e
        do RGPD cumpre as medidas de segurança técnicas e organizativas
        adequadas.
      </p>
      <p>
        O Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, mantém uma
        base de dados com o registo dos seus clientes. Os dados presentes nesta
        base são unicamente os dados prestados pelos próprios na altura do seu
        registo, sendo recolhidos e processados automaticamente, nos termos
        aprovados pela Comissão Nacional de Proteção de Dados, pelo Radiologia
        ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, entidade responsável pelo
        correspondente ficheiro.
      </p>
      <p>
        {" "}
        Em caso algum será solicitada informação sobre convicções filosóficas ou
        políticas, filiação partidária ou sindical, fé religiosa, vida privada e
        origem racial ou étnica bem como os dados relativos à saúde e à vida
        sexual, incluindo os dados genéticos.
      </p>
      <p>
        {" "}
        Em caso algum levaremos a cabo qualquer das seguintes atividades com os
        dados pessoais que nos sejam facultados através deste site:
      </p>
      <p>
        {" "}
        Ceder a outras pessoas ou outras entidades, sem o consentimento prévio
        do titular dos dados;
      </p>
      <p>
        Transferir para fora do Espaço Económico Europeu (EEE), sem o
        consentimento prévio do titular dos dados.
      </p>

      <h2>3. Finalidades do Tratamento de Dados Pessoais</h2>
      <p>
        Os dados pessoais que tratamos através desta página serão unicamente
        utilizados para as seguintes finalidades:
      </p>
      <ul>
        <li>3.1. Processamento de encomendas;</li>
        <li>
          3.2. Comunicação com clientes/participantes e esclarecimento de
          dúvidas;
        </li>
        <li>3.3. Processamento de pedidos de informação;</li>
        <li>3.4. Processamento de reclamações;</li>
        <li>3.5. Atividades de análise estatística;</li>
        <li>
          3.6. Verificar, manter e desenvolver sistemas e análises estatísticas;
        </li>
        <li>
          3.7. Comunicações de marketing direto (caso tenha consentido no
          tratamento dos seus dados pessoais para esta finalidade);
        </li>
        <li>3.8. Prevenção e combate à fraude;</li>
        <li>
          3.9. Solicitação de comentários a produtos ou serviços adquiridos;
        </li>
        <li>3.10. Realização de inquéritos de satisfação.</li>
        <li>
          3.11. Gestão e participação em eventos, passatempos e campanhas.
        </li>
        <p>
          O Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, garante a
          confidencialidade de todos os dados fornecidos pelos seus clientes.
          Não obstante o Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>
          proceder à recolha e ao tratamento de dados de forma segura e que
          impede a sua perda ou manipulação, utilizando as técnicas mais
          aperfeiçoadas para o efeito, informamos que a recolha em rede aberta
          permite a circulação dos dados pessoais sem condições de segurança,
          correndo o risco de ser vistos e utilizados por terceiros não
          autorizados.
        </p>
        <p>
          No momento da recolha dos dados pessoais, salvo nos campos em que for
          indicado o contrário, o utilizador poderá, voluntariamente,
          disponibilizar os dados pessoais, sem que a falta de resposta implique
          um decréscimo na qualidade ou quantidade dos serviços correspondentes
          (a menos que esteja indicada outra coisa). Não obstante, a falta de
          resposta aos dados, considerados obrigatórios, implicará a
          impossibilidade de aceder ao serviço para o qual os dados foram
          solicitados.
        </p>
      </ul>

      <h2>4. Cessão de Dados Pessoais</h2>
      <p>
        Por forma a poder cumprir com o objeto do presente website, o Radiologia
        ao Centro irá ceder os seus dados pessoais a outras entidades, que os
        irão tratar, para as seguintes finalidades:
      </p>
      <ul>
        <li>4.1. Atividades de gestão e processamento de pagamentos;</li>
        <li>4.1.1. Prestação dos serviços contratados.</li>
        <li>
          4.1.2. Gestão e participação em eventos, passatempos e campanhas.
        </li>
      </ul>
      <p>
        4.2. As entidades a quem o Radiologia ao Centro -
        <i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i> irá ceder os seus dados pessoais para
        os tratarem nos termos acima referidos terão a seguinte natureza:
      </p>
      <ul>
        <li>4.2.1. Entidades seguradoras;</li>
        <li>
          4.2.2. Entidades terceiras relacionadas com a prestação dos serviços
          contratados;
        </li>
        <li>4.2.3. Entidades de gestão e processamento de pagamentos.</li>
      </ul>

      <h2>5. Medidas de Segurança</h2>
      <p>
        O Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i> declara que
        implementou e continuará a implementar as medidas de segurança de
        natureza técnica e organizativa necessárias para garantir a segurança
        dos dados de carácter pessoal que lhe sejam fornecidos visando evitar a
        sua alteração, perda, tratamento e/ou acesso não autorizado, tendo em
        conta o estado atual da tecnologia, a natureza dos dados armazenados e
        os riscos a que estão expostos.
      </p>
      <p>
        O Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i> garante a
        confidencialidade de todos os dados fornecidos pelos seus clientes quer
        no registo, quer no processo de compra/encomenda de produtos ou
        serviços. A recolha e tratamento de dados realiza-se de forma segura e
        que impede a sua perda ou manipulação. O utilizador poderá verificar que
        o seu browser é seguro se o símbolo do cadeado aparecer ou se o endereço
        começar com https em vez de http.
      </p>
      <p>
        O Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, sempre que
        aceda a qualquer dado pessoal, compromete-se a:
      </p>
      <p>
        Armazená-los por intermédio de medidas de segurança, legalmente
        exigíveis, de natureza técnica e organizacional, que garantam a sua
        segurança, evitando assim a alteração, perda, tratamento ou acesso não
        autorizado, em conformidade com o estado da tecnologia em cada momento,
        a natureza dos dados e os possíveis riscos a que estejam expostos;
      </p>
      <p>
        Utilizar os dados exclusivamente para as finalidades previamente
        definidas;{" "}
      </p>
      <p>
        Certificar-se de que os dados são tratados unicamente pelos
        trabalhadores cuja intervenção seja necessária para a prestação do
        serviço estando os mesmos obrigados ao dever de sigilo e
        confidencialidade. Existindo a possibilidade de a informação ser
        revelada a terceiros, estes devem ser obrigados a guardar a devida
        confidencialidade em conformidade com o previsto neste documento.
      </p>

      <h2>6. Comunicações Comerciais e Promocionais</h2>
      <p>
        Um dos propósitos para os quais tratamos dados pessoais fornecidos pelos
        utilizadores é enviar comunicações eletrónicas com informações relativas
        a comunicações comerciais e promocionais.
      </p>
      <p>
        Sempre que fizermos uma comunicação deste tipo, será direcionado
        exclusivamente aos utilizadores que as tenham autorizado previamente e
        de forma expressa. De acordo com o disposto no Decreto-Lei n.º 7/2004,
        de 7 de janeiro, no caso de o utilizador desejar deixar de receber
        comunicações comerciais ou promocionais do Radiologia ao Centro -
        <i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>, poderá solicitar a oposição do
        serviço enviando um e-mail.
      </p>

      <h2>7. Exercício dos Direitos</h2>
      <p>
        De acordo com as disposições da LDPD e do RGPD, o utilizador pode
        exercer a todo o tempo os seus direitos de acesso, retificação,
        apagamento, limitação, oposição e portabilidade, através de solicitação
        por qualquer dos seguintes meios:
      </p>
      <p>
        Os dados pessoais são tratados com o nível de proteção legalmente
        exigível para garantir a segurança dos mesmos e evitar a sua alteração,
        perda, tratamento ou acesso não autorizado, tendo em conta o estado da
        tecnologia, sendo o utilizador consciente e aceitando que as medidas de
        segurança em Internet não são inexpugnáveis.
      </p>
      <ul>
        <li>E-mail: radiologiaaocentro@gmail.com</li>
        <li>
          Telefone: +351 917 635 120 - horário de atendimento das 14h às 17h, de
          segunda a sexta-feira.
        </li>
      </ul>

      <p>
        Caso o utilizador deseje, a qualquer momento, deixar de fazer parte da
        base de dados do Radiologia ao Centro -<i>𝗞𝗻𝗼𝘄𝗹𝗲𝗱𝗴𝗲 𝘁𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺𝘀 𝗹𝗶𝘃𝗲𝘀</i>{" "}
        poderá exercer esse direito através destes contactos.
      </p>

      <h2>8. Autoridade de Controlo</h2>
      <p>
        Nos termos legais, o titular dos dados tem o direito de apresentar uma
        reclamação em matéria de proteção de dados pessoais à autoridade de
        controlo competente, a Comissão Nacional de Proteção de Dados (CNPD).
      </p>
    </div>
  );
}

export default PrivacyPolicy;
