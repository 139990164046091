import React from "react";
import "../../App.css";

function CommissionsPage() {
  return (
    <div className="commissions-container">
      <h1>Comissões</h1>

      <div className="commissions-section">
        <h2>Comissão Organizadora</h2>
        <ul>
          <li>Alda Pinto</li>
          <li>Carla Solano</li>
          <li>Daniel Matos</li>
          <li>Elza Santo</li>
          <li>Marta Rosa</li>
          <li>Paula Marques</li>
          <li>Paulo Matos</li>
          <li>Tiago Patrão</li>
          <li>Tiago Araújo</li>
        </ul>
      </div>

      <div className="commissions-section">
        <h2>Comissão Científica</h2>
        <ul>
          <li>Adriana Marques</li>
          <li>Alda Pinto</li>
          <li>Carla Solano</li>
          <li>Filipa Pires</li>
          <li>Magda Malva</li>
          <li>Monica Martins</li>
          <li>Nuno Rodrigues</li>
          <li>Odete Miguel</li>
        </ul>
      </div>
      <div className="commissions-section">
        <h2>Comissões Técnicas</h2>
        <ul>
          <h3>Comissão e gestão de catering e nutrição</h3>
          <li>Adélia Santos</li>
          <li>Alda Pinto</li>
          <li>Fátima Santos</li>
          <li>Marta Rosa</li>
          <li>Paula Marques</li>
          <li>Teresa Neves</li>
          <li>e-mail: radiologiaaocentro@gmail.com</li>
          <h3>Curadoria e montagem da exposição</h3>
          <li>Carla Solano</li>
          <li>Daniel Matos</li>
          <li>Marta Rosa</li>
          <li>Paula Marques</li>
          <li>Teresa Neves</li>
          <li>Tiago Araújo</li>
          <li>Tiago Patrão</li>
          <li>e-mail: radiologiaaocentro@gmail.com</li>
          <h3>Comissão de comunicação e gestão de parcerias</h3>
          <li>Anabela Gomes</li>
          <li>Carla Solano</li>
          <li>email: radiologiaaocentro@gmail.com</li>
          <h3>Comissão para a tradução técnica de documentação</h3>
          <li>Paula Marques</li>
          <h3>Comissão responsável pela certificação</h3>
          <li>Eventqualia</li>
          <li>Elza Santo</li>
          <li>email: nucleoformacaotr@gmail.com</li>
          <h3>Comunicação com entidades oficiais</h3>
          <li>Daniel Matos</li>
          <li>email: radiologiaaocentro@gmail.com</li>
          <h3>Conceção, construção e edição do dossier</h3>
          <li>Carla Solano</li>
          <li>Tiago Araújo</li>
          <li>e-mail: radiologiaaocentro@gmail.com</li>
          <h3>Design gráfico e marketing</h3>
          <li>DUAS FACES</li>
          <h3>Empresa de contabilidade</h3>
          <li>CONTRARELOGIO</li>
          <h3>Gestão de Programa e Palestrantes</h3>
          <li>Adriana Marques </li>
          <li>Alda Pinto </li>
          <li>Carla Solano </li>
          <li>Filipa Pires</li>
          <li>Magda Malva </li>
          <li>Monica Martins </li>
          <li>Nuno Rodrigues </li>
          <li>Email: radiologiaaocentro@gmail.com</li>
          <h3>Estratégia, logística e produção técnica</h3>
          <li>Tiago Araújo</li>
          <li>e-mail: radiologiaaocentro@gmail.com</li>
          <h3>Plataformas de inscrição</h3>
          <li>Eventqualia</li>
          <li>Carla Solano</li>
          <li>Paulo Matos</li>
          <li>email: radiologiaaocentro@gmail.com</li>
          <h3>Gestão e legalização dos slogans</h3>
          <li>Tiago Patrão</li>
          <li>email: radiologiaaocentro@gmail.com</li>
          <h3>Secretariado</h3>
          <li>Elza Santo</li>
          <li>Carolina Gomes</li>
          <li>Mariana Fernandes</li>
          <li>e-mail: radiologiaaocentro@gmail.com </li>
          <h3>Marketing e comunicação audiovisual</h3>
          <li>Duas Faces</li>
          <li>Carla Solano</li>
          <li>Tiago Araújo</li>
          <li>e-mail: radiologiaaocentro@gmail.com </li>
          <h3>Tesouraria, contabilidade interna</h3>
          <li>Contrarelógio, empresa de contabilidade</li>
          <li>Paulo Matos</li>
          <li>e-mail:ahd-geral@outlook.com </li>
          <h3>Relações internacionais</h3>
          <li>Paula Marques</li>
          <li>e-mail: radiologiaaocentro@gmail.com</li>
          <h3>Revisão de textos</h3>
          <li>Carla Solano</li>
          <li>e-mail: radiologiaaocentro@gmail.com </li>
          <h3>Informações</h3>
          <li>e-mail: radiologiaaocentro@gmail.com </li>
        </ul>
      </div>
    </div>
  );
}

export default CommissionsPage;
