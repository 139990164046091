import React from "react";
import "../../App.css";

function TermsOfUse() {
  return (
    <div className="ExhibitionPage-container">
      <h1>Termos de Utilização</h1>

      <p>
        Bem-vindo ao site oficial "radiologiaaocentro.com". Se continuar a
        navegar e a usar este website, concorda em cumprir e ficar vinculado aos
        seguintes termos e condições de utilização, que, juntamente com a nossa
        Política de Privacidade, regem a relação entre [Nome do Seu Website] e
        você em relação a este website. Se discordar de alguma parte destes
        termos e condições, por favor, não utilize o nosso website.
      </p>

      <h2>
        A utilização deste website está sujeita aos seguintes termos de
        utilização:
      </h2>

      <ol>
        <li>
          <strong>Aviso de Conteúdo:</strong> O conteúdo das páginas deste
          website destina-se apenas a informação geral e uso. Está sujeito a
          alterações sem aviso prévio.
        </li>

        <li>
          <strong>Utilização de Cookies:</strong> Este website utiliza cookies
          para monitorizar as preferências de navegação. Se permitir a
          utilização de cookies, informações pessoais podem ser armazenadas por
          nós para uso por terceiros.
        </li>

        <li>
          <strong>Privacidade:</strong> A sua utilização deste website também é
          regida pela nossa Política de Privacidade. Por favor, reveja a nossa
          Política de Privacidade para obter mais informações sobre como
          recolhemos e utilizamos dados.
        </li>

        <li>
          <strong>Conduta do Utilizador:</strong> A utilização não autorizada
          deste website pode dar origem a uma reivindicação por danos e/ou
          constituir um delito criminal.
        </li>

        <li>
          <strong>Ligações para Outros Websites:</strong> O nosso website pode
          conter ligações para outros websites. Essas ligações são fornecidas
          para sua conveniência para fornecer mais informações. Elas não
          significam que endossamos o(s) website(s). Não temos responsabilidade
          pelo conteúdo do(s) website(s) vinculado(s).
        </li>

        <li>
          <strong>Renúncia de Garantia:</strong> Nem nós nem terceiros
          fornecemos qualquer garantia ou garantia quanto à exatidão,
          pontualidade, desempenho, integridade ou adequação das informações e
          materiais encontrados ou oferecidos neste website para qualquer
          finalidade específica. Reconhece que tais informações e materiais
          podem conter imprecisões ou erros, e renunciamos expressamente à
          responsabilidade por tais imprecisões ou erros na extensão máxima
          permitida por lei.
        </li>

        <li>
          <strong>Propriedade Intelectual:</strong> Este website contém material
          que é propriedade ou licenciado para nós. Este material inclui, mas
          não está limitado ao design, layout, aparência e gráficos. A
          reprodução é proibida, exceto em conformidade com o aviso de direitos
          autorais, que faz parte destes termos e condições.
        </li>

        <li>
          <strong>Alterações aos Termos:</strong> "radiologiaaocentro.com" pode
          rever estes termos de utilização do seu website a qualquer momento sem
          aviso prévio. Ao utilizar este website, concorda em ficar vinculado à
          versão então atualizada destes Termos de Utilização.
        </li>
      </ol>

      <p>
        <strong>Informações de Contacto:</strong> Se tiver alguma dúvida sobre
        estes Termos de Utilização, entre em contacto connosco em E-mail:
        radiologiaaocentro@gmail.com.
      </p>

      <p>
        <strong>Data de Entrada em Vigor:</strong> Estes Termos de Utilização
        foram atualizados pela última vez em 29-11-2023.
      </p>
    </div>
  );
}

export default TermsOfUse;
